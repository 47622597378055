.setting_modal_body {
  height: auto;
  min-height: 60vh;
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.setting_modal_body::-webkit-scrollbar {
  display: none;
}
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  margin-top: 20px;
  gap: 50px;
  width: 90%;
}
.image_conatainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.banner_image {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}
.action_icons {
  font-size: 20px;
  cursor: pointer;
}
.actions_container {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 15px;
}
.tab_pill {
  margin: 0;
}
