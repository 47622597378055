.language_tab {
  display: inline-block;
  padding: 0.1rem 1rem;
  border-radius: 24px;
  margin: 0 0.25rem;
  background-color: grey;
  color: white;
  cursor: pointer;
  margin-bottom: 8px;
  text-align: center;
}

.vertical_align_middle td {
  vertical-align: middle;
  text-align: center;
}

.language_tab.active {
  background-color: green;
  color: white;
}

.slug_icon {
  position: absolute;
  top: 25%;
  right: 10px;
  font-size: 18px;
}
