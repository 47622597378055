.vertical_align_middle td {
  vertical-align: middle;
  text-align: center;
}
.setting_modal_body {
  height: auto;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.setting_modal_body::-webkit-scrollbar {
  display: none;
}
