.vertical_align_middle td {
  vertical-align: middle;
  text-align: center;
}
.setting_modal_body {
  height: auto;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.setting_modal_body::-webkit-scrollbar {
  display: none;
}
.add_card{
  border: 2px dashed gray;
  padding: 15px;
}
.select_testimonial_list:hover{
  cursor: pointer;
  background-color: rgba(54, 69, 79, 0.1) !important;
}
.selected_testimonial{
  background-color: rgba(54, 69, 79, 0.3) !important;
}
.selected_testimonial.selected_testimonial{
  background-color: rgba(54, 69, 79, 0.3) !important;
}
.slide_container{
  background: #cfc7c7;
  color: white;
  border-radius: 7px;
  padding-inline: 25px;
  min-width: min-content;
  width: 100%;
  margin-bottom: 22px;
}
.slide_container:hover .remove_btn{
  display: flex !important;
}
.testimonial_container{
padding-bottom: 40px;
}
.remove_btn{
  line-height: 0.7;
  margin-right: 18px;
  display: none !important;
}
.card {
  width: 310px;
}
.card_settingIcon {
  z-index: 100;
  position: absolute;
  right: 7px;
  top: 5px;
  cursor: pointer;
  display: none;
}
.card:hover i {
  -webkit-animation:rotate 3s linear infinite;
  -moz-animation:rotate 3s linear infinite;
  animation:rotate 3s linear infinite;
}
.card:hover .card_settingIcon {
  display: block;
}
.card_settingIcon i {
  font-size: 22px;
}
.slide_name{
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: bolder;
  line-height: 3;
}
.modal_body{
  max-height: calc(100vh - 40vh);
  overflow-y: scroll;
}
.data {
  background: #fff;
  outline: none;
  padding: 27px;
  box-shadow: 0 3px 16px rgb(0 0 0 / 16%);
  border-radius: 4px;
  height: 100%;
  min-height: 190px;
  max-height: 300px;
  overflow: hidden;
}
.customer {
  color: #212121;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  padding-top: 10px;
  margin: 0;
  position: relative;
}
p.copy {
  color: #334e7c;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  margin: 0;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 4;
  position: relative;
  min-height: 77px;
  word-wrap: break-word;
}
p.copy::before {
  width: 16px;
  height: 20px;
  margin: 0 auto 10px;
  content: '';
  background-repeat: no-repeat;
  background-image: url('~assets/images/quote-image.webp');
  display: block;
  position: relative;
}
.wave_image {
  bottom: 0px;
  left: 0;
}
.star_container {
  width: fit-content;
  margin: 0 auto;
  margin-top: 15px;
}

.featured_list{
  height: 220px !important; 
}
.loading_card{
  height: 100%;
  width: 330px;
}

/* Animations */
@keyframes rotate{
  100%{
    -webkit-transform: rotate(360deg); 
    transform:rotate(360deg); 
  }
}

/* NEw */

.selected_name_card{
  border: 2px solid midnightblue;
  border-radius: 6px;
  padding-inline: 10px;
  cursor: pointer;
}
.selected_name_card:hover{
  border-color: red;
  color: red !important;
}

.selected_name_container{
  display: flex;
    gap: 12px;
    padding-left: 11px;
}
.selected_no span{
  background: white;
  color: black;
  border: 1px solid lightslategrey;
  border-radius: 5px;
  padding-inline: 4px;
  font-weight: 100;

}
.font_bold{
  font-weight: bolder;
}

.table_container{
  min-height: 316px;
    max-height: calc(100vh - 40vh);
    overflow-y: scroll;
    position: relative;
}
.sticky{
  position: sticky;
  top: 0;
}
.load_more_btn{
  background-color: #212121 !important;
  width: 50px !important;
}
.slidewrapper{
  min-width: inherit;
}