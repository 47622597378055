body>iframe {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.search-info-icon {
    font-size: 18px;
    font-weight: 800;
}

.search-info-text {
    font-size: 16px;
    font-weight: 800;
}

.right-5 {
    right: 5px;
}

.lead-date,
.lead-date-container button {
    width: 117px;
    font-size: 11px;
}

.lead-date-container {
    top: -65px;
}

.select_page_limit {
    height: fit-content;
}

.page_entries {
    padding: 0;
    margin: 0;
    font-size: small;
}

.width-160 {
    width: 160px;
}

.width-260 {
    width: 260px;
    min-width: 260px;
}

.width-120 {
    width: 120px;
    min-width: 120px;
}

.break-word td {
    word-break: break-all;
}

.select_page_limit {
    font-size: 14px;
    color: #7a6fbe;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 5px;
}

.profile_icon {
    color: white;
    font-weight: bold;
    font-size: small;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.addbtn {
    right: 18px;
}

.icon-container {
    font-size: 14px;
}

.icon-container>div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
}

.icons {
    font-size: 20px;
    cursor: pointer;
    line-height: 16px;
    outline: none ;
}

.action-tab {
    text-align: center;
    width: 6rem;
}

.font-11 {
    font-size: 11px;
}

.min-w-260 {
    min-width: 260px;
}

.min-w-216 {
    min-width: 216px;
}

.min-w-119 {
    min-width: 119px;
}

.min-w-111 {
    min-width: 111px;
}

.min-w-201 {
    min-width: 201px;
}

.add_section {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dotted gray;
    height: 50px;
    cursor: pointer;
    background-color: black;
    transition: 0.4s ease all;
    color: white;
    font-weight: bold;
    border: 2px solid gray;
}

.add_section:hover {
    background-color: white;
    color: black;
}

.section_row {
    border-radius: 6px;
    box-shadow: 1px 3px 3px 0px;
}


.w-0 {
    width: 0;
}

.min-w-135 {
    min-width: 135px;
}

.min-w-260 {
    min-width: 260px;
}

.min-w-216 {
    min-width: 216px;
}

.min-w-119 {
    min-width: 119px;
}

.min-w-111 {
    min-width: 111px;
}

.min-w-201 {
    min-width: 201px;
}

.add-tag {
    cursor: pointer;
    margin-bottom: 0.5rem;
    font-size: 14px;
}

.add-tag:hover {
    color: black;
}

.filedrop_text {
    width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

// min-height

.min-h-0 {
    min-height: 0;
}

.video_card_container {
    min-width: 155px;
    max-width: 155px;
    box-shadow: 2px 0 5px;
    border-radius: 6px;
    min-height: 115px;
    max-height: 115px;
    transition: .4s ease-in-out;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.skeleton-loader {
    background: linear-gradient(120deg,
            #e5e5e5 30%,
            #f2f2f2 38%,
            #f2f2f2 40%,
            #e5e5e5 48%);
    background-size: 212%;
    background-position: 100% 0;
    animation: anim 1.5s infinite;
    height: 20px;
    border-radius: 7px;
}

@keyframes anim {
    to {
        background-position: -100% 0;
    }
}

.video-close {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    padding-inline: 3px;
    color: white;
    background-color: red;
    font-size: 12px;
    border-radius: 50%;
    transform: translate(-3px, 6px);
}

.video_card_container img {
    border-radius: 6px 6px 0px 0px;
}

.video_card_container .video-title {
    font-size: 12px;
    padding-inline: 10px;
    padding-block: 5px;
    font-weight: 600;
}

.video_card_container[data-selected="true"]::after {
    content: "Selected";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    color: white;
    font-weight: bolder;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(47, 161, 255, 0.493);
}

.attachment-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    color: black;
}

.attachment-icon i  {
    color: black;
    font-size: 45px;
}
.attachment-icon-mdicon  {
    color: black;
    font-size: 45px;
}

// Tinymce 
.tox:not(.tox-tinymce-inline) .tox-editor-header {
    padding: 0 !important;
}

@media only screen and (max-width: 735px) {
    .lead-date-container {
        position: unset !important;
    }
}

.select_add_tag {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    color: black;
    padding-block: 5px;
    cursor: pointer;
}

.password_eye {
    top: 7px;
    right: 10px;
    transform: translateY(10%);
    font-size: 18px;
    color: gray;
}

.full-width-blur {
    position: absolute;
    width: 100%;
    left: 0;
    backdrop-filter: blur(20px);
    top: 0;
    height: 100%;
    font-weight: bolder;
}

.lang-tab {
    min-width: 60px;
    white-space: nowrap;
}
.add_language_badge{
    background-color: transparent;
    color: green;
    border: 2px solid lightgreen;
    transition: .3s ease-in-out all;
  }
  .add_language_badge:hover{
    background-color: green;
    color: white;
  }

  .w-8rem{
    width: 8rem;
  }

  .videoloader {
    height: 120px;
    width: 155px;
  }

  .ml-2rem{
    margin-left: 2rem;
  }