.tab_pill {
  display: inline-block;
  padding: 0.1rem 1rem;
  border-radius: 24px;
  margin: 0 0.25rem;
  background-color: grey;
  color: white;
  margin-bottom: 8px;
  text-align: center;
  white-space: nowrap;
}

.tab_pill.active {
  background-color: green;
  color: white;
}
