
  .disable-pagination a{
    display: none;
  }
  .pagination > li > a
{
    background-color: white;
    color: black;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover
{
    color: black;
    background-color: rgba(52, 58, 64s, 0.1);
    border-color: #ddd;
}

.pagination > .active > a
{
    color: white;
    background-color: rgb(52, 58, 64s);
    border: solid 1px rgb(52, 58, 64s);
}

.pagination > .active > a:hover,.pagination > .active > a:focus
{
    color: white;
    background-color: rgb(52, 58, 64s);
    border: solid 1px rgb(52, 58, 64s);
}