.accordion_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  transition: all ease-in-out 0.3s;
  border: 1px solid rgb(202, 202, 202);
  margin-bottom: 24px;
  box-sizing: border-box;
  padding: 0 24px;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-top: 8px;
}

.title_contanier {
  align-items: center !important;
  gap: 20px;
}
.title_div {
  width: calc(100% - 40px);
  box-sizing: border-box;
  padding: 24px 0;
  cursor: pointer;
  font-size: 16px;
  border-radius: 2px;
  transition: all ease-in-out 0.5s;
}
.action_container {
  gap: 5px;
}
.action_icons {
  font-size: 20px;
  cursor: pointer;
}
.question {
  color: #343434;
}

.text {
  opacity: 0;
  transition: all ease-in-out 0.5s;
  font-size: 1px;
}

.question {
  flex-grow: 1;
}

.content_div {
  width: 100%;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  text-align: justify;
  padding-right: 100px;
  color: #343434;
}

.active {
  padding-bottom: 16px;
}
